import React from 'react';
import { useIsSubmittingAnswers, useIsSuccess } from 'hooks';
import cx from 'classnames';
import PageWrapper from '../PageWrapper';
import './LoadingPage.css';
import { useTranslation } from 'react-i18next';

export default function LoadingPage() {
  const { t } = useTranslation();
  const isSubmitting = useIsSubmittingAnswers();
  const isSuccess = useIsSuccess();

  return (
    <PageWrapper className="LoadingPage">
      <div className="LoadingPage-content">
        {(isSubmitting || isSuccess) && (
          <div className={cx('circle-loader', !isSubmitting && 'load-complete')}>
            <div className={cx('checkmark draw', !isSubmitting && 'checkmark-show')} />
          </div>
        )}

        {!isSubmitting && !isSuccess && (
          <>
            <div className="LoadingPage-error">
              <span>X</span>
            </div>
            <div className="LoadingPage-title">{t('general:error')}</div>
          </>
        )}
      </div>
    </PageWrapper>
  );
}
