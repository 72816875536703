import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { Actions, State } from './types';
import reducer from './reducer';

export { default as actions } from './actions';

const initialState: State = {
  isFetchingTournamentInfo: false,
  isSubmittingAnswers: false,
  tournamentInfo: undefined,
  answers: {},
  completedMap: {},
  isSuccess: true
};

export const Store = createContext<{
  state: State;
  dispatch: Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => {}
});

export function StoreProvider(props: { children: React.ReactElement }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Store.Provider value={{ state, dispatch }}>{props.children}</Store.Provider>;
}

export function useSelector<R>(selector: (state: State) => R) {
  const { state } = useContext(Store);
  return selector(state);
}

export function useDispatch() {
  return useContext(Store).dispatch;
}
