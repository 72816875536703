import React from 'react';
import { useParams } from 'react-router-dom';
import './Survey.css';
import { Button } from 'react-onsenui';
import { useTranslation } from 'react-i18next';
import {
  useOnAnswerQuestion,
  useSurvey,
  useQuestions,
  useOnSubmitSurvey,
  useNavigateHome
} from 'hooks';
import Question from './Question';

export default function Survey() {
  const { id } = useParams();
  const survey = useSurvey(id);
  const questions = useQuestions();
  const onAnswerQuestion = useOnAnswerQuestion(id);
  const onSubmitSurvey = useOnSubmitSurvey(id);
  const navigateHome = useNavigateHome();
  const { t } = useTranslation();

  if (!survey) {
    return null;
  }

  return (
    <div className="Survey">
      <ul className="Survey-list">
        {questions.map((question, index) => (
          <li key={question.id}>
            <Question
              index={index}
              onChange={onAnswerQuestion}
              questionId={question.id}
              disabled={survey.isCompleted}
              value={survey.answers[question.id]}
            />
          </li>
        ))}
      </ul>

      {!survey.isCompleted ? (
        <Button modifier="large--cta" onClick={onSubmitSurvey}>
          {t('general:submit')}
        </Button>
      ) : (
        <Button modifier="large--cta" onClick={navigateHome}>
          {t('general:back')}
        </Button>
      )}
    </div>
  );
}
