import { TournamentInfo } from './types';
import { config } from './config';

function getCompletedSurveyMap() {
  return JSON.parse(localStorage.getItem('surveys:completedSurveysMap') || '{}');
}

const completedSurveysMap: Record<string, any> = getCompletedSurveyMap();

export async function fetchTournamentInfo(
  code: string
): Promise<{
  tournamentInfo: TournamentInfo;
  answers: Record<string, any>;
  completedMap: Record<string, boolean>;
}> {
  if (!code) {
    throw new Error('The code is not valid');
  }

  const response = await fetch(`${config.apiBaseUrl}/survey/profile/${code}`).then((response) =>
    response.json()
  );
  return {
    tournamentInfo: (response as any) as TournamentInfo,
    answers: completedSurveysMap,
    completedMap: Object.keys(completedSurveysMap).reduce((acc, item) => {
      return Object.assign({}, acc, { [item]: Boolean(completedSurveysMap[item]) });
    }, {})
  };
}

export async function submitResponse(data: {
  urlCode: string;
  externalFixtureId: number;
  deviceType: string;
  osType: string;
  browserName: string;
  browserVersion: string;
  responses: {
    questionId: number;
    optionId: any;
  }[];
}) {
  return await fetch(`${config.apiBaseUrl}/survey/response`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function markSurveyCompleted(surveyId: number, answers: Record<string, any>) {
  const map = getCompletedSurveyMap();
  localStorage.setItem(
    'surveys:completedSurveysMap',
    JSON.stringify(Object.assign({}, map, { [surveyId]: answers }))
  );
}

export function getCode() {
  return localStorage.getItem('surveys:code');
}

export function setCode(code: string) {
  localStorage.setItem('surveys:code', code);
}
