import React, { useMemo } from 'react';
import { Option, QuestionType, QuestionValue } from 'types';
import { Radio, Range, Segment } from 'react-onsenui';
import star from './icons/star.svg';
import starEmpty from './icons/star-empty.svg';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const quality = [
  { title: 'very soft', color: '#E23C39' },
  { title: 'soft', color: '#EC903A' },
  { title: 'perfect', color: '#72C040' },
  { title: 'hard', color: '#EC903A' },
  { title: 'very hard', color: '#E23C39' }
];

type Props = {
  type: QuestionType;
  options: Option[] | undefined;
  value: QuestionValue;
  disabled: boolean;
  onChange(value: QuestionValue): void;
};

export default function QuestionControl({ type, value, onChange, options, disabled }: Props) {
  const { t } = useTranslation();
  if (type === 'slider' && !value) {
    value = { from: undefined, to: undefined };
  }

  const selectedOptionIndex = useMemo(
    () => (options || []).findIndex((item) => item.id === value),
    [options, value]
  );

  return (
    <div>
      {type === 'slider' && (
        <div className="Slider-container">
          <div className="Question-slider">
            <div className="Slider-tooltip">{(value as any).from}</div>
            <Range
              value={(value as any).from as number}
              modifier="material"
              className="Slider"
              onChange={(event) =>
                onChange({
                  from: parseInt((event.target as any).value),
                  to: (value as any).to
                })
              }
              disabled={disabled}
            />
            <div className="Slider-data">
              <span>0</span>
              <span>{t('questions:from')}</span>
              <span>94</span>
            </div>
          </div>

          <div className="Question-slider">
            <div className="Slider-tooltip">{(value as any).to}</div>
            <Range
              value={(value as any).to as number}
              modifier="material"
              className="Slider"
              onChange={(event) =>
                onChange({
                  from: (value as any).from,
                  to: parseInt((event.target as any).value)
                })
              }
              disabled={disabled}
            />
            <div className="Slider-data">
              <span>0</span>
              <span>{t('questions:to')}</span>
              <span>94</span>
            </div>
          </div>
        </div>
      )}
      {type === 'radio' && options && (
        <>
          {options.map((item) => (
            <label className="Question-radio" key={item.id}>
              <Radio
                modifier="material"
                value={item.id.toString()}
                checked={value === item.id}
                onChange={() => onChange(item.id)}
                disabled={disabled}
              />
              <span className="Question-label">{t(`options:${item.translationKey}`)}</span>
            </label>
          ))}
        </>
      )}
      {type === 'button-group' && (
        <Segment modifier="material" className="Question-segment" index={999}>
          {options!.map((item) => (
            <button key={item.id} disabled={disabled} onClick={() => onChange(item.id)}>
              {t(`options:${item.translationKey}`)}
            </button>
          ))}
        </Segment>
      )}
      {type === 'rating' && (
        <div className="Question-star">
          {options!.map((item, index) => (
            <button
              key={item.id}
              className="Star-button"
              onClick={() => onChange(item.id)}
              disabled={disabled}
            >
              <img
                src={value && selectedOptionIndex >= index ? star : starEmpty}
                alt="star"
                width="30"
                height="30"
              />
            </button>
          ))}
        </div>
      )}
      {type === 'quality' && (
        <div className="Question-quality">
          {options!.map((item, index) => (
            <button
              key={index}
              className={cx('Quality-button', item.id === value && 'Quality-button_selected')}
              onClick={() => onChange(item.id)}
              disabled={disabled}
            >
              <div style={{ background: quality[index].color }} className="Quality-label" />
              <span>{t(`options:${item.translationKey}`)}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
