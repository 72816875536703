import React from 'react';
import { ListItem } from 'react-onsenui';
import { useTranslation } from 'react-i18next';
import './SurveysListItem.css';

type Props = {
  canNavigate: boolean;
  isCompleted: boolean;
  round: string;
  team1Name: string;
  team1Picture: string;
  team2Picture: string;
  team2Name: string;
  score: [number, number] | [undefined, undefined];
  onClick: (() => void) | undefined;
};

export default function SurveysListItem({
  canNavigate,
  isCompleted,
  onClick,
  round,
  team1Name,
  team1Picture,
  score,
  team2Picture,
  team2Name
}: Props) {
  const { t } = useTranslation();

  return (
    <ListItem
      className="SurveyListItem"
      modifier={canNavigate ? 'chevron' : undefined}
      onClick={onClick}
    >
      <div className="SurveyListItem-content">
        {isCompleted && (
          <span role="img" aria-label="Completed" className="SurveyListItem-icon_completed">
            ✔️
          </span>
        )}
        <div className="SurveyListItem-header">{t(`rounds:${round}`)}</div>
        <div className="SurveyListItem-match">
          <div className="SurveyListItem-team SurveyListItem-team1">
            <div className="SurveyListItem-teamName">{team1Name}</div>
            <img className="SurveyListItem-teamPicture" src={team1Picture} alt={team1Name} />
          </div>
          <div className="SurveyListItem-score">
            {score[0]} - {score[1]}
          </div>
          <div className="SurveyListItem-team SurveyListItem-team2">
            <img className="SurveyListItem-teamPicture" src={team2Picture} alt={team2Name} />
            <div className="SurveyListItem-teamName">{team2Name}</div>
          </div>
        </div>
      </div>
    </ListItem>
  );
}
