import { Actions, State } from './types';
import { ActionTypes } from './actions';

export default function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case ActionTypes.postAnswers: {
      return Object.assign({}, state, {
        isSubmittingAnswers: true
      });
    }

    case ActionTypes.postAnswersCompleted: {
      return Object.assign({}, state, {
        isSubmittingAnswers: false,
        isSuccess: action.payload
      });
    }

    case ActionTypes.fetchTournamentInfo: {
      return Object.assign({}, state, {
        isFetchingTournamentInfo: true
      });
    }

    case ActionTypes.fetchTournamentInfoSuccess: {
      return Object.assign({}, state, {
        isFetchingTournamentInfo: false,
        ...action.payload
      });
    }

    case ActionTypes.answerQuestion: {
      return Object.assign({}, state, {
        answers: Object.assign({}, state.answers, {
          [action.payload.fixtureId]: Object.assign({}, state.answers[action.payload.fixtureId], {
            [action.payload.questionId]: action.payload.value
          })
        })
      });
    }

    case ActionTypes.markSurveyCompleted: {
      return Object.assign({}, state, {
        completedMap: Object.assign({}, state.completedMap, {
          [action.payload]: true
        })
      });
    }

    default:
      return state;
  }
}
