import React from 'react';
import './HomePage.css';
import SurveysList from '../SurveysList';
import { useInitApp, useIsFetching, useTournamentInfo } from 'hooks';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../PageWrapper';
import HomePagePlaceholder from './HomePagePlaceholder';

export default function HomePage() {
  const tournamentInfo = useTournamentInfo();
  const { t } = useTranslation();
  const isFetching = useIsFetching();
  useInitApp();

  return (
    <PageWrapper className="HomePage">
      {isFetching ? (
        <HomePagePlaceholder />
      ) : (
        <>
          <header className="HomePage-header">
            <div className="HomePage-logo">
              <img src={tournamentInfo?.leagueLogo} alt="tournament-logo" />
            </div>
            <h2 className="HomePage-title">{t('general:welcomemessage')}</h2>
          </header>
          <div className="HomePage-content">
            <SurveysList />
          </div>
        </>
      )}
    </PageWrapper>
  );
}
