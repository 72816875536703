import React, { useCallback } from 'react';
import QuestionControl from './QuestionControl';
import { useTranslation } from 'react-i18next';
import { useQuestion } from 'hooks';

type Props = {
  index: number;
  questionId: number;
  disabled: boolean;
  value: any;
  onChange(questionId: number, value: number): void;
};

export default function Question({ index, questionId, disabled, onChange, value }: Props) {
  const { t } = useTranslation();
  const question = useQuestion(questionId);
  const onChangeCallback = useCallback(
    (value: number) => {
      onChange(question.id, value);
    },
    [onChange, question]
  );

  if (!question) {
    return null;
  }

  return (
    <div className="Survey-question">
      <div className="Question-title">
        {index + 1}. {t(`questions:${question.translationKey}`)}
      </div>
      <QuestionControl
        type={question.type}
        options={question.options}
        disabled={disabled}
        onChange={onChangeCallback}
        value={value}
      />
    </div>
  );
}
