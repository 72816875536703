import React, { ReactNode } from 'react';
import { Page } from 'react-onsenui';
import cx from 'classnames';
import './PageWrapper.css';

type Props = {
  className: string;
  children: ReactNode;
};

export default function PageWrapper({ children, className }: Props) {
  return (
    <Page>
      <div className={cx('PageWrapper', className)}>{children}</div>
    </Page>
  );
}
