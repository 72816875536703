import React from 'react';
import { List as OnsenList } from 'react-onsenui';
import SurveysListItem from '../SurveysListItem';
import { useSurveysIds } from 'hooks';
import { useTranslation } from "react-i18next"
import './SurveysList.css'

export default function SurveysList() {
  const { t } = useTranslation();
  const surveys = useSurveysIds();

  return surveys.length > 0 ? (
    <OnsenList
      className="SurveysList"
      modifier="inset"
      dataSource={surveys}
      renderRow={(item) => <SurveysListItem key={item} id={item} />}
    />
  ) : (
    <>
      <div className="SurveysList-divider"/>
      <p className="SurveysList-message">{t('general:nofixturesavailable')}</p>
      <p className="SurveysList-message">{t('general:pleasecomeback')}</p>
    </>
  );
}
