import React from 'react';
import './SurveyPage.css';
import { useParams } from 'react-router-dom';
import { useIsFetching, useSurvey } from 'hooks';
import Survey from '../Survey';
import PageWrapper from '../PageWrapper';
import SurveyPagePlaceholder from './SurveyPagePlaceholder';
import { useTranslation } from 'react-i18next';

export default function SurveyPage() {
  const params = useParams<{ id: string }>();
  const survey = useSurvey(Number(params.id));
  const isFetching = useIsFetching();
  const { t } = useTranslation();

  if (isFetching) {
    return (
      <PageWrapper className="SurveyPage">
        <SurveyPagePlaceholder />
      </PageWrapper>
    );
  }

  if (!survey) {
    return null;
  }

  return (
    <PageWrapper className="SurveyPage">
      <header className="SurveyPage-header">
        <div className="SurveyPage-description">
          <div>{t(`rounds:${survey.round}`)}</div>
          <div>{survey.date}</div>
        </div>
        <div className="SurveyPage-result">
          <div className="SurveyPage-group">
            <img className="SurveyPage-flag" src={survey.team1Picture} alt={survey.team1Name} />
            <span className="SurveyPage-team">{survey.team1Name}</span>
          </div>
          <div className="SurveyPage-score">
            {survey.score[0]} - {survey.score[1]}
          </div>
          <div className="SurveyPage-group">
            <img className="SurveyPage-flag" src={survey.team2Picture} alt={survey.team2Name} />
            <span className="SurveyPage-team">{survey.team2Name}</span>
          </div>
        </div>
        <div className="SurveyPage-description">
          <div>{survey.venueName}</div>
          <div>{survey.venueCity}</div>
        </div>
      </header>

      <Survey />
    </PageWrapper>
  );
}
