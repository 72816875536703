import React from 'react';
import { BrowserRouter, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import HomePage from 'components/HomePage';
import SurveyPage from 'components/SurveyPage';
import LoadingPage from 'components/LoadingPage';
import './App.css';
import { useEffectOnce, useNavigateHome } from 'hooks';

const AnimatedSwitch = withRouter(({ location }) => {
  const { pathname } = useLocation();
  const navigateHome = useNavigateHome();

  useEffectOnce(() => {
    if (pathname.includes('survey') || pathname.includes('loading')) {
      navigateHome();
    }
  });

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="slide" timeout={250}>
        <Switch location={location}>
          <Route path="/survey/:id" component={SurveyPage} />
          <Route path="/loading" component={LoadingPage} />
          <Route path="/:code" component={HomePage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <BrowserRouter>
        <AnimatedSwitch />
      </BrowserRouter>

      <footer className="App-footer">{t('general:poweredbygraspro')}</footer>
    </div>
  );
}
