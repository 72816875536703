import { useCallback, useEffect, useMemo, EffectCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { actions, useDispatch, useSelector } from 'store';
import { deviceDetect, isMobile } from 'react-device-detect';
import {
  fetchTournamentInfo,
  getCode,
  markSurveyCompleted,
  setCode,
  submitResponse
} from './data-flows';
import i18n from 'i18next';

export function useEffectOnce(effect: EffectCallback) {
  useEffect(effect, []);
}

export function useIsFetching() {
  return useSelector((state) => state.isFetchingTournamentInfo);
}

export function useTournamentInfo() {
  return useSelector((state) => state.tournamentInfo);
}

export function useSurvey(id: number) {
  const fixture = useSelector((state) => {
    return state.tournamentInfo?.fixtures.find((item) => item.externalFixtureId === id);
  })!;
  const answers = useSelector((state) => state.answers[id]);
  const isCompleted = useSelector((state) => state.completedMap[id] || false);

  return useMemo(() => {
    return {
      team1Name: fixture?.homeTeamName || '',
      team1Picture: fixture?.homeTeamLogo || '',
      team2Name: fixture?.awayTeamName || '',
      team2Picture: fixture?.awayTeamLogo || '',
      score: fixture?.finalScore
        ? (fixture.finalScore.split('-').map(Number) as [number, number])
        : ([undefined, undefined] as [undefined, undefined]),
      round: fixture?.round || '',
      date: fixture?.date.slice(0, 10) || '',
      venueName: fixture?.venueName || '',
      venueCity: fixture?.venueCity || '',
      answers: answers || {},
      isCompleted
    };
  }, [answers, fixture, isCompleted]);
}

export function useSurveysIds() {
  return useSelector(
    (state) => state.tournamentInfo?.fixtures.map((item) => item.externalFixtureId) || []
  );
}

export function useQuestions() {
  return useSelector((state) => state.tournamentInfo?.questions || []);
}

export function useQuestion(id: number) {
  return useSelector((state) => state.tournamentInfo?.questions.find((item) => item.id === id))!;
}

export function useOnAnswerQuestion(fixtureId: number) {
  const dispatch = useDispatch();
  return useCallback(
    (questionId: number, value: number) => {
      dispatch(actions.answerQuestion({ fixtureId, questionId, value }));
    },
    [dispatch, fixtureId]
  );
}

export function useIsSubmittingAnswers() {
  return useSelector((state) => state.isSubmittingAnswers);
}

function useFetchTournamentInfo() {
  const dispatch = useDispatch();
  return useCallback(
    async (code: string) => {
      dispatch(actions.fetchTournamentInfo());
      const response = await fetchTournamentInfo(code);
      dispatch(actions.fetchTournamentInfoSuccess(response));
      return response;
    },
    [dispatch]
  );
}

export function useInitApp() {
  const { pathname } = useLocation();
  const codeFromUrl = useMemo(() => {
    return pathname ? pathname.replace('/', '') : '';
  }, [pathname]);

  const fetchTournamentInfo = useFetchTournamentInfo();
  const tournamentInfo = useTournamentInfo();

  useEffectOnce(() => {
    if (tournamentInfo) {
      return;
    }

    const codeFromStorage = getCode();

    if (codeFromUrl && codeFromUrl !== codeFromStorage) {
      setCode(codeFromUrl);
    }

    fetchTournamentInfo(codeFromUrl || (codeFromStorage as string)).then((response) => {
      i18n.changeLanguage(response.tournamentInfo.language.toLowerCase());
    });
  });
}

export function useNavigateHome(replace: boolean = false) {
  const history = useHistory();
  return useCallback(() => {
    const code = getCode();
    if (replace) {
      history.replace(`/${code}`);
    } else {
      history.goBack();
    }
  }, [history, replace]);
}

export function useNavigateBack() {
  const history = useHistory();
  return useCallback(() => {
    history.goBack();
  }, [history]);
}

export function useOnSubmitSurvey(fixtureId: number) {
  const dispatch = useDispatch();
  const history = useHistory();
  const questions = useQuestions();
  const answers = useSelector((state) => state.answers[fixtureId]);
  const navigateHome = useNavigateHome(true);
  const navigateBack = useNavigateBack();

  return useCallback(async () => {
    const deviceInfo = deviceDetect();
    const response = {
      urlCode: getCode() as string,
      externalFixtureId: fixtureId,
      deviceType: isMobile ? 'mobile' : 'desktop',
      osType: deviceInfo.osName || deviceInfo.os,
      browserName: deviceInfo.browserName,
      browserVersion: deviceInfo.browserMajorVersion,
      userAgent: deviceInfo.userAgent,
      responses: questions.map((item) => ({
        questionId: item.id,
        optionId: answers && answers[item.id] ? answers[item.id] : null
      }))
    };

    dispatch(actions.postAnswers());
    history.push('/loading');
    const result = await submitResponse(response);
    const isSuccess = result.status === 200;
    dispatch(actions.postAnswersCompleted(isSuccess));

    if (isSuccess) {
      markSurveyCompleted(fixtureId, answers);
      dispatch(actions.markSurveyCompleted(fixtureId));
      setTimeout(() => {
        navigateHome();
      }, 3000);
      return;
    }

    setTimeout(() => {
      navigateBack();
    }, 3000);
  }, [answers, dispatch, fixtureId, history, navigateBack, navigateHome, questions]);
}

export function useIsSuccess() {
  return useSelector((state) => state.isSuccess);
}

export function useSurveyListItemProps(surveyId: number) {
  const survey = useSurvey(surveyId);
  const history = useHistory();
  const goToSurvey = useCallback(() => history.push(`/survey/${surveyId}`), [history, surveyId]);

  const canNavigate = survey.score.every((score: number | undefined) => {
    return typeof score === 'number' ? Number.isInteger(score) : false;
  });

  return {
    canNavigate,
    isCompleted: survey.isCompleted,
    onClick: canNavigate ? goToSurvey : undefined,
    round: survey.round,
    team1Name: survey.team1Name,
    team1Picture: survey.team1Picture,
    score: survey.score,
    team2Picture: survey.team2Picture,
    team2Name: survey.team2Name
  };
}
