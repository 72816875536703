import i18n from 'i18next';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: ['general', 'questions', 'options', 'rounds'],
    fallbackLng: 'en',
    cleanCode: true,
    defaultNS: 'general',
    debug: false,
    interpolation: {
      escapeValue: false
    },
    backend: {
      projectId: 'da174c93-cbcc-4060-8d19-98ea7f576f26',
      apiKey: '7b123368-9ef3-4ac5-a49f-ca52a2f7e206',
      referenceLng: 'en'
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    }
  });

export default i18n;
