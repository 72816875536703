import { TournamentInfo } from '../types';

function createAction<P, T>(type: T) {
  function actionCreator(payload: P): { type: T; payload: P } {
    return { type, payload };
  }

  actionCreator.toString = (): T => type;
  actionCreator.type = type;

  return actionCreator;
}

export enum ActionTypes {
  fetchTournamentInfo = 'Fetch tournament info',
  fetchTournamentInfoSuccess = 'Fetch tournament info success',
  postAnswers = 'Post answers',
  postAnswersCompleted = 'Post answers success',
  answerQuestion = 'Answer question',
  markSurveyCompleted = 'Mark survey completed'
}

// prettier-ignore
export default {
  fetchTournamentInfo: createAction<void, ActionTypes.fetchTournamentInfo>(
    ActionTypes.fetchTournamentInfo
  ),
  fetchTournamentInfoSuccess: createAction<{ tournamentInfo: TournamentInfo, answers: Record<string, any>, completedMap: Record<string, boolean> }, ActionTypes.fetchTournamentInfoSuccess>(
    ActionTypes.fetchTournamentInfoSuccess
  ),
  answerQuestion: createAction<{ fixtureId: number, questionId: number, value: number }, ActionTypes.answerQuestion>(
    ActionTypes.answerQuestion
  ),
  postAnswers: createAction<void, ActionTypes.postAnswers>(
    ActionTypes.postAnswers
  ),
  postAnswersCompleted: createAction<boolean, ActionTypes.postAnswersCompleted>(
    ActionTypes.postAnswersCompleted
  ),
  markSurveyCompleted: createAction<number, ActionTypes.markSurveyCompleted>(
    ActionTypes.markSurveyCompleted
  )
};
