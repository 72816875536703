import React from 'react';

export default function HomePagePlaceholder() {
  return (
    <div className="Placeholder-container">
      <div className="Placeholder Placeholder-logo" />
      <div className="Placeholder Placeholder-header" />
      <div className="Placeholder Placeholder-title" />
      <div>
        <div className="Placeholder Placeholder-item" />
        <div className="Placeholder Placeholder-item" />
        <div className="Placeholder Placeholder-item" />
        <div className="Placeholder Placeholder-item" />
        <div className="Placeholder Placeholder-item" />
        <div className="Placeholder Placeholder-item" />
      </div>
    </div>
  );
}
