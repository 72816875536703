import React from 'react';

export default function SurveyPagePlaceholder() {
  const questionPlaceholder = (
    <div className="Question-group">
      <div className="Placeholder Placeholder-question" />
      <div className="Placeholder Placeholder-option" />
      <div className="Placeholder Placeholder-option" />
    </div>
  );

  return (
    <div className="Placeholder-container">
      <div className="Placeholder Placeholder-date" />
      <div className="Placeholder Placeholder-result" />
      <div className="Placeholder Placeholder-name" />
      {questionPlaceholder}
      {questionPlaceholder}
      {questionPlaceholder}
    </div>
  );
}
